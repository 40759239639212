import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'menu',
    loadChildren: () => import('./nav/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'addcompany',
    loadChildren: () => import('./modals/addcompany/addcompany.module').then( m => m.AddcompanyPageModule)
  },
  {
    path: 'newadmin',
    loadChildren: () => import('./modals/newadmin/newadmin.module').then( m => m.NewadminPageModule)
  },
  {
    path: 'newcompany',
    loadChildren: () => import('./modals/newcompany/newcompany.module').then( m => m.NewcompanyPageModule)
  },
  {
    path: 'newonlineapplication',
    loadChildren: () => import('./modals/newonlineapplication/newonlineapplication.module').then( m => m.NewonlineapplicationPageModule)
  },
  {
    path: 'newclaims',
    loadChildren: () => import('./modals/newclaims/newclaims.module').then( m => m.NewclaimsPageModule)
  },
  {
    path: 'newpayreceive',
    loadChildren: () => import('./modals/newpayreceive/newpayreceive.module').then( m => m.NewpayreceivePageModule)
  },
  {
    path: 'newinquiry',
    loadChildren: () => import('./modals/newinquiry/newinquiry.module').then( m => m.NewinquiryPageModule)
  },
  {
    path: 'add-com-docs',
    loadChildren: () => import('./modals/add-com-docs/add-com-docs.module').then( m => m.AddComDocsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
